exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-all-startups-js": () => import("./../../../src/pages/all-startups.js" /* webpackChunkName: "component---src-pages-all-startups-js" */),
  "component---src-pages-dato-cms-danish-startups-in-numbers-page-slug-js": () => import("./../../../src/pages/{datoCmsDanishStartupsInNumbersPage.slug}.js" /* webpackChunkName: "component---src-pages-dato-cms-danish-startups-in-numbers-page-slug-js" */),
  "component---src-pages-get-listed-confirmation-js": () => import("./../../../src/pages/get-listed/confirmation.js" /* webpackChunkName: "component---src-pages-get-listed-confirmation-js" */),
  "component---src-pages-get-listed-index-js": () => import("./../../../src/pages/get-listed/index.js" /* webpackChunkName: "component---src-pages-get-listed-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

