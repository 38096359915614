import 'intersection-observer';
import React from 'react';
import { GlobalStyles } from 'twin.macro';
import NewsletterPopup from 'modules/NewsletterPopup';
import TagManager from 'react-gtm-module';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/700.css';
import './src/styles/index.css';

console.log('Howdy fellow nerd, hope you are having a mighty fine day! 🙌 🚀 🎉');

TagManager.initialize({
  // gtmId: 'GTM-MGMKHKC', // Separate container for this site, unused
  gtmId: 'GTM-WXHNSDL',
});

export const wrapRootElement = ({ element }) => (
  <>
    <GlobalStyles />
    {element}
    <NewsletterPopup />
  </>
);
