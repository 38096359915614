import arrow from 'assets/link-arrow.svg';
import externalLinkArrow from 'assets/external-link-arrow.svg';
import tw, { css, styled } from 'twin.macro';
import { ReactSVG } from 'react-svg';
import { useOnScreen } from 'utils/hooks';

export const innerStyles = tw`w-full mx-auto 2xl:max-w-6xl 3xl:max-w-7xl`;
export function Inner({ children, ...rest }) {
  return (
    <div css={innerStyles} {...rest}>
      {children}
    </div>
  );
}

export const sectionStyles = tw`relative w-full flex flex-col justify-between items-start py-12 px-5 md:(px-8 py-24) lg:px-20 overflow-hidden`;
export const SectionStyled = styled.section(({ visible }) => [
  sectionStyles,
  css`
    opacity: 0;
    transform: translateY(20px);
    transition: 1500ms opacity, 1500ms transform;
  `,
  visible &&
    css`
      opacity: 1;
      transform: translateY(0);
    `,
]);

export const sectionBlueGradientStyles = css`
  ${tw`relative min-h-screen flex items-center justify-center flex flex-col-reverse sm:py-0 lg:(flex-row)`};
  :after {
    content: '';
    ${tw`absolute inset-0 h-full z-minus-1`}
    background-image: linear-gradient(0deg, #0a1236 0%, #203075 100%);
  }
`;

export function Section({ children, show, ...rest }) {
  const [ref, visible] = useOnScreen();
  return (
    <SectionStyled ref={ref} visible={show || visible} {...rest}>
      {children}
    </SectionStyled>
  );
}

export const Half = styled.div(() => [
  tw`md:w-full lg:w-1/2`,
  css`
    :first-of-type {
      ${tw`lg:pr-0`}
    }
    :last-of-type {
      ${tw`pl-0`}
    }
  `,
]);

export const PageTitle = tw.h1`font-bold mt-0 text-3xl xl:text-5xl`;

export const SectionTitle = tw.h2`font-semibold m-0 leading-tight text-xl sm:text-4xl`;
export const SectionHeader = tw.div`flex justify-between items-center w-full mb-10 flex-col items-start sm:(pl-0 flex-row items-center)`;

export function A({ children, href, withArrow, ...rest }) {
  return (
    <a href={href} target="_blank" rel="noopener noreferrer" {...rest}>
      {children}
      {withArrow && <ReactSVG src={externalLinkArrow} />}
    </a>
  );
}

export const contentStyles = css`
  font-size: 1.125rem;

  h1,
  h2 {
    margin-bottom: 0.5rem;
    margin-top: 1rem;
  }

  svg {
    transform: scale(1.5);
  }

  a {
    ${tw`relative hover:(transition-colors text-green) underline`}
  }

  p {
    margin-bottom: 1rem;

    &:last-of-type {
      margin-bottom: 0;
    }

    & + ul,
    & + ol {
      margin-top: 1rem;
    }

    img {
      margin: 1rem auto;
    }
  }

  ul {
    ${'' /* margin: 1rem 0; */}
  }

  li {
    position: relative;
    padding-left: 2rem;
  }

  li:before {
    position: absolute;
    content: url('${arrow}');
    left: 0;
  }
`;

export const textStyles = css`
  h1,
  h2,
  h3 {
    ${tw`font-semibold mb-4 mt-12`}
  }
  h1 {
    ${tw`text-4xl`}
  }
  h2 {
    ${tw`text-2xl`}
  }
  h3 {
    ${tw`text-xl`}
  }
  h4 {
    ${tw`text-lg`}
  }
  ul,
  ol,
  p {
    font-size: 1.125rem;
    line-height: 1.625;
    margin-bottom: 1.625em;
  }
  ul,
  ol {
    ${tw`pl-5`}

    li {
      ${tw`mt-2 pl-2`}
    }
  }
  ul {
    list-style: disc;

    ul {
      list-style: circle;
    }
  }
  ol {
    list-style: decimal;

    ol {
      list-style: lower-alpha;
    }
  }
  li {
    list-style: inherit;
  }
  iframe {
    width: 100%;
  }
  a {
    position: relative;
    font-weight: 400;
    transition: color 150ms linear;
    ${tw`text-current underline hover:text-green hover:no-underline`}
  }
  figcaption {
    margin-top: 0.5rem;
    font-size: 0.875rem;
  }
`;
