import React, { useEffect, useState } from 'react';
import tw, { css } from 'twin.macro';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import { cn, validateEmail } from '../utils';
import '../styles/newsletter-popup.css';
import { A } from 'components';
import { Checkbox, checkedStyles } from 'modules/NewsletterSlim';

const smallTextStyles = tw`w-11/12 ml-5 text-xxs sm:(ml-0) leading-tight`;

function NewsletterPopup() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [jobTitle, setJobTitle] = useState('');
  const [email, setEmail] = useState('');
  const [fieldsValid, setFieldsValid] = useState();
  const [iConsent, setIConsent] = useState(false);
  const [formStatus, setFormStatus] = useState();
  const [error, setError] = useState(false);
  const [openNewsletterForm, setOpenNewsletterForm] = useState(false);
  const [closePopup, setClosePopup] = useState(false);
  const [popup, setPopup] = useState(false);

  useEffect(() => {
    let counter = 0;
    if (firstName.length > 0) counter += 1;
    if (lastName.length > 0) counter += 1;
    if (jobTitle.length > 0) counter += 1;
    if (validateEmail(email)) counter += 1;
    if (iConsent) counter += 1;
    setFieldsValid(counter);
    setError(false);
  }, [firstName, lastName, jobTitle, email, iConsent]);

  const onSubmit = async () => {
    const fields = {
      FIRSTNAME: firstName,
      LASTNAME: lastName,
      JOBTITLE: jobTitle,
    };

    const response = await addToMailchimp(email, fields);

    if (response.result === 'success') setFormStatus(response.result);
    if (response.result === 'error') setError(response);
  };

  useEffect(() => {
    if (typeof window !== 'undefined' && formStatus === 'success') {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'newsletterSubmissionCompleted',
      });

      // if (window.gtag) {
      //   window.gtag('event', 'newsletter_signup', {
      //     event_category: 'sign_up',
      //     event_label: 'newsletterSubmissionCompleted',
      //   });
      // }
    }
  }, [formStatus]);

  useEffect(() => {
    if (!popup) {
      setTimeout(() => {
        setPopup(true);
      }, 2000);
    }
  }, []);

  if (closePopup) return null;

  return (
    <div
      className={cn(
        'newsletter-popup',
        openNewsletterForm && 'newsletter-popup-opened',
        popup && 'newsletter-popup-slide-up'
      )}
    >
      <div className="newsletter-popup-button" tw="items-center!">
        <button className="newsletter-popup-x" onClick={() => setClosePopup(true)}>
          <span tw="text-xl h-full font-normal opacity-80 hover:opacity-100 leading-[24px]">
            &times;
          </span>
        </button>
        <button onClick={() => setOpenNewsletterForm(true)}>
          <p className="newsletter-popup-title">Stay Updated</p>
        </button>
      </div>
      <div className="newsletter-popup-form">
        <button className="newsletter-popup-x" onClick={() => setOpenNewsletterForm(false)}>
          <span tw="text-xl h-full font-normal opacity-80 hover:opacity-100 leading-[24px]">
            &times;
          </span>
        </button>
        {formStatus === 'success' && (
          <>
            <h1 tw="text-xl text-green font-bold mb-4">Welcome! 🎉</h1>
            <p tw="mb-2">
              We look forward to having you on board & deliver relevant updates to your inbox! 📬
            </p>
          </>
        )}
        {!formStatus && (
          <>
            <h1>Stay Updated 🚀</h1>
            <p>
              Sign up to our newsletter and stay up to date on the latest news, insights and events
              from the Danish cutting-edge tech scene.
            </p>
            <div>
              {error && <p dangerouslySetInnerHTML={{ __html: error.msg }} />}
              <div>
                <Input
                  id="mce-FNAME"
                  value={firstName}
                  label="First Name"
                  onChange={setFirstName}
                />
                <Input id="mce-MMERGE2" value={lastName} label="Last Name" onChange={setLastName} />
              </div>
              <Input id="mce-MMERGE3" value={jobTitle} label="Job Title" onChange={setJobTitle} />
              <Input id="mce-EMAIL" value={email} label="Email" onChange={setEmail} />
              <label htmlFor="consent" tw="flex justify-between items-start my-2">
                <Checkbox
                  id="consent"
                  onClick={() => setIConsent((val) => !val)}
                  css={iConsent && checkedStyles}
                  role="button"
                  aria-label="consent"
                  tabIndex="0"
                  onKeyPress={() => setIConsent((val) => !val)}
                />
                <span css={smallTextStyles}>
                  When you sign up for Digital Hub Denmark’s newsletter, you give us consent to send
                  you news and insights on all things digital in the State of Denmark 🚀
                  <br /> You can read more about how we process your personal data in our personal{' '}
                  <A href="https://digitalhubdenmark.dk/privacy-policy" tw="underline">
                    data policy
                  </A>
                  , as well as what rights you have.
                </span>
              </label>
              <div>
                <label htmlFor="consent">
                  <div
                    id="consent"
                    onClick={() => setIConsent((val) => !val)}
                    role="button"
                    aria-label="consent"
                    tabIndex="0"
                    onKeyPress={() => setIConsent((val) => !val)}
                  />
                </label>
                <button
                  className="newsletter-popup-submit-button"
                  onClick={() => onSubmit()}
                  disabled={fieldsValid !== 5}
                  style={{ opacity: fieldsValid !== 5 ? 0.75 : 1 }}
                >
                  Sign up
                </button>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

function Input({ id, label, onChange, type, value }) {
  const [inFocus, setInFocus] = useState();
  const completed = value?.length > 0 && !inFocus;

  return (
    <label htmlFor="mce-MMERGE3">
      <span>{label}</span>
      <input
        id={id}
        name={id}
        value={value}
        onChange={(e) => onChange(e.target.value)}
        type={type || 'text'}
        onFocus={() => setInFocus(true)}
        onBlur={() => setInFocus()}
      />
    </label>
  );
}

export default NewsletterPopup;
