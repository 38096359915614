export const cn = (...args) => args.filter(Boolean).join(' ');

export const validateEmail = (email) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const validateURL = (url) =>
  /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i.test(
    url
  );

export const debounce = (fn, time) => {
  let timeoutId;
  return wrapper;

  function wrapper(...args) {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      timeoutId = null;
      fn(...args);
    }, time);
  }
};

export const capitalizeFirstChar = (str) => str.charAt(0).toUpperCase() + str.slice(1);

export const camelize = (str) =>
  str
    .replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, (match, index) => {
      if (+match === 0) return ''; // or if (/\s+/.test(match)) for white spaces
      return index === 0 ? match.toLowerCase() : match.toUpperCase();
    })
    .replace(/[^\w\s]/gi, '');

export const createRandomID = () =>
  Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, '')
    .substr(2, 10);

export const alphabetize = (a, b, name) => {
  const key = name;
  if (a[key].toLowerCase() < b[key].toLowerCase()) {
    return -1;
  }
  if (a[key].toLowerCase() > b[key].toLowerCase()) {
    return 1;
  }
  return 0;
};

export const checkIfDanish = (str) =>
  str.includes('æ') ||
  str.includes('ø') ||
  str.includes('å') ||
  str.includes('Æ') ||
  str.includes('Ø') ||
  str.includes('Å');

/**
 * Simple function to prevent widow in strings
 *
 */
export function preventWidow(string) {
  return string?.replace(/\s([^\s<]+)\s*$/, '\u00A0$1');
}

/**
 *  More robust Widont function to prevent widow in text, by replacing last whitespace
 *  between words with non-breaking space \u00A0 (which renders in a string without v-html).
 *  Supports html content with inline tags.
 *
 *  @param  {String} text to parse
 */
export function widont(text) {
  if (!text) return text;
  const inlineTags = 'a|em|span|strong|i|b';
  const word = `(?:<(?:${inlineTags})[^>]*?>)*?[^\\s<>]+(?:</(?:${inlineTags})[^>]*?>)*?`;

  /* eslint-disable prefer-template,operator-linebreak,space-infix-ops */
  const reWidont = new RegExp(
    '(' + // matching group 1
      '\\s+' +
      word + // space and a word with a possible bordering tag
      '\\s+' +
      word + // space and a word with a possible bordering tag
      ')' +
      '(?:\\s+)' + // one or more space characters
      '(' + // matching group 2
      '[^<>\\s]+' + // nontag/nonspace characters
      `(?:\\s*</(?:${inlineTags})[^>]*?>\\s*\\.*)*?` + // one or more inline closing tags. can be surronded by spaces and followed by a period.
      '(?:\\s*?</(?:p|h[1-6]|li|dt|dd)>|$)' + // allowed closing tags or end of line
      ')',
    'gi'
  );
  return text.replace(reWidont, '$1\u00A0$2');
}
