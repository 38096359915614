/* eslint-disable react/prop-types */
import React, { useEffect, useRef } from 'react';
import tw, { css } from 'twin.macro';
import VanillaTilt from 'vanilla-tilt';
import Link from 'components/Link';
import { ArrowIcon } from 'components/Icons';

const reverseStyles = css`
  ${tw`flex flex-row-reverse`}
  svg {
    transform: rotate(180deg);
    ${tw`ml-0! mr-7`}
  }
`;
const hideStyles = css`
  visibility: hidden !important;
  opacity: 0;
`;

const disabledStyles = css`
  opacity: 0.25;
`;

const buttonStyles = css`
  ${tw`inline-block bg-[#ffffff20]`}
  box-shadow: 0px 10px 10px #00000025;

  @media only screen and (max-width: 768px) {
    transform: none !important;
  }

  a,
  button {
    ${tw`py-4 px-5 font-semibold flex justify-between items-center w-full border border-solid border-white cursor-pointer outline-none focus:(border-green)`}
  }
  svg {
    ${tw`ml-7`}
  }
`;

function TiltButton({
  onClick = undefined,
  label = '',
  accent = '#5ec2bb',
  disabled = false,
  hide = undefined,
  reverse = undefined,
  to = '',
  arrowDirection = 'right',
  external = false,
  ...rest
}) {
  const tiltRef = useRef();

  useEffect(() => {
    const tiltOptions = {
      max: 12,
      speed: 1800,
      reverse: false,
      glare: true,
      'max-glare': 0.5,
      gyroscope: false,
    };

    VanillaTilt.init(tiltRef.current, tiltOptions);
  }, []);

  return (
    <div
      css={[buttonStyles, disabled && disabledStyles, hide && hideStyles]}
      ref={tiltRef}
      {...rest}
    >
      {onClick ? (
        <button
          onClick={onClick}
          css={reverse ? reverseStyles : undefined}
          disabled={disabled || hide}
          tabIndex="0"
          type="button"
        >
          <span>{label}</span>
          <ArrowIcon accent={accent} arrowDirection={arrowDirection} />
        </button>
      ) : (
        <Link
          href={to}
          css={[reverse && reverseStyles, hide && hideStyles]}
          disabled={hide}
          tabIndex="0"
          target={external ? '_blank' : '_self'}
        >
          <span>{label}</span>
          <ArrowIcon accent={accent} arrowDirection={arrowDirection} />
        </Link>
      )}
    </div>
  );
}

export default TiltButton;
